<template>
  <v-dialog
    width="800"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    transition="slide-x-transition"
    v-model="get_modal_view_funcionario"
  >
    <v-card class="expande-horizontal wrap column">
      <v-list
        class="fonte pa-0 ma-0"
        dark
        style="border-radius: 0px;"
        nav
        dense
        :color="$theme.secondary"
      >
        <v-list-item>
          <v-btn dark @click="fecha_modal_view_funcionario" icon>
            <v-icon :color="$theme.primary">
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{
              get_funcionario._id
                ? get_funcionario.nome
                : "Cadastrar Funcionário"
            }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="valida_form" :color="$theme.primary">
              <span class="fonte white--text font-weight-bold"> Salvar </span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <div
        :style="`background: ${$theme.secondary}`"
        class="expande-horizontal centraliza column"
      >
        <v-avatar v-if="get_funcionario.photo && get_funcionario.photo.length">
          <v-img
            :src="get_funcionario.photo ? get_funcionario.photo[0] : ''"
          ></v-img>
        </v-avatar>
        <v-avatar v-else>
          <v-icon color="white" size="36">
            mdi-account-circle-outline
          </v-icon>
          <!-- <v-img src="img/logo_mini.png"></v-img> -->
        </v-avatar>
        <h3 class="white--text fonte mt-3 mb-3">
          {{ get_funcionario.finance_balance }}
        </h3>
      </div>

      <v-tabs
        :color="$theme.primary"
        class="fonte"
        centered
        v-model="tab"
        dark
        show-arrows
        :background-color="$theme.secondary"
      >
        <v-tab class="font-weight-bold">Informações</v-tab>
        <v-tab class="font-weight-bold" v-if="get_funcionario.finance_control"
          >Controle Financeiro</v-tab
        >
        <v-tab
          @click="getValeSalario"
          class="font-weight-bold"
          v-if="get_funcionario.finance_control"
          >Saldo</v-tab
        >
      </v-tabs>

      <div
        v-show="tab === 0"
        class="expande-horizontal wrap py-6 pb-0"
        style="min-height: 340px;"
      >
        <v-flex xs12>
          <v-form ref="form">
            <!-- <ModalSendArchive /> -->
            <v-flex class="px-3 pb-6" xs12 md4>
              <v-switch
                v-model="get_funcionario.finance_control"
                dense
                flat
                clearable
                :color="$theme.primary"
                label="Habilitar controle financeiro"
              ></v-switch>
            </v-flex>
            <div class="expande-horizontal wrap">
              <v-flex class="px-3" xs12 md6>
                <v-select
                  v-model="get_funcionario.activeTenant"
                  dense
                  flat
                  clearable
                  :items="getLoggedUser.tenant"
                  item-text="nome"
                  item-value="_id"
                  :color="$theme.primary"
                  :rules="[v => !!v || 'Preencha este campo']"
                  label="Empresa"
                ></v-select>
              </v-flex>
              <v-flex class="px-3" xs12 md6>
                <v-select
                  v-model="get_funcionario.permissao"
                  dense
                  flat
                  item-value="id"
                  item-text="nome"
                  :items="get_permissoes.docs"
                  :color="$theme.primary"
                  label="Perfil de permissão"
                ></v-select>
              </v-flex>
            </div>
            <div class="expande-horizontal wrap">
              <v-flex class="px-3" xs12 md6>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  Nome
                </span>
                <v-text-field
                  v-model="get_funcionario.nome"
                  dense
                  solo
                  flat
                  outlined
                  clearable
                  :color="$theme.primary"
                  :rules="[v => !!v || 'Preencha este campo']"
                  label="ex: Roberto"
                ></v-text-field>
              </v-flex>

              <v-flex class="px-3" xs12 md6>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  Email
                </span>
                <v-text-field
                  v-model="get_funcionario.email"
                  dense
                  solo
                  flat
                  outlined
                  clearable
                  :color="$theme.primary"
                  label="ex: email@gmail.com"
                ></v-text-field>
              </v-flex>
            </div>

            <div class="expande-horizontal">
              <v-flex class="px-3" xs12 md4>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  CPF
                </span>
                <v-text-field
                  v-model="get_funcionario.cpf"
                  dense
                  solo
                  flat
                  outlined
                  v-mask="['###.###.###-##']"
                  clearable
                  :color="$theme.primary"
                  label="ex: 000.000.000-00"
                ></v-text-field>
              </v-flex>
              <v-flex v-if="get_funcionario.permissao" class="px-3" xs12 md4>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  Senha
                </span>
                <v-text-field
                  v-model="get_funcionario.senha"
                  dense
                  solo
                  flat
                  type="password"
                  outlined
                  clearable
                  hint="A senha será atualizada para essa"
                  :color="$theme.primary"
                  label="******"
                ></v-text-field>
              </v-flex>
              <v-flex class="px-3" xs12 md4>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  Nascimento
                </span>
                <v-text-field
                  v-model="get_funcionario.data_nascimento"
                  dense
                  solo
                  flat
                  outlined
                  v-mask="['##/##/####']"
                  clearable
                  :color="$theme.primary"
                  label="ex: 12/12/1984"
                ></v-text-field>
              </v-flex>
            </div>
          </v-form>
        </v-flex>
      </div>
      <div
        v-show="tab === 1"
        class="expande-horizontal pt-3 px-6"
        style="min-height: 340px;"
      >
        <v-flex xs12>
          <div class="expande-horizontal fonte wrap">
            <v-flex xs12>
              <div class="expande-horizontal centraliza column pb-6 pt-6">
                <h3
                  v-if="get_funcionario.finance_value_to_pay"
                  class="fonte green--text"
                >
                  {{
                    $helper.formataSaldo(
                      get_funcionario.finance_value_to_pay.replace(",", ".") ||
                        0
                    )
                  }}
                </h3>
                <span
                  v-if="get_funcionario.receive_payment_recurrency"
                  class="fonte fonteMini text-center"
                  >Saldo adicionado de forma
                  {{ get_funcionario.receive_payment_recurrency }}</span
                >
              </div>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                outlined
                label="Valor do pagamento"
                v-model="get_funcionario.finance_value_to_pay"
                v-mask="['#,##', '##,##', '###,##', '####,##', '#####,##']"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="px-1">
              <v-select
                v-model="get_funcionario.receive_payment_recurrency"
                :items="['manual', 'semanal', 'mensal', 'anual']"
                label="Recorrência do pagamento"
              ></v-select>
            </v-flex>
            <v-flex
              v-if="get_funcionario.receive_payment_recurrency === 'mensal'"
              xs12
              md6
              class="px-1"
            >
              <v-autocomplete
                label="Dia do pagamento"
                v-model="get_funcionario.receive_payment_month_date"
                :items="[
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                  '24',
                  '25',
                  '26',
                  '27',
                  '28',
                  '29',
                  '30',
                ]"
              ></v-autocomplete>
            </v-flex>
            <v-flex
              v-if="get_funcionario.receive_payment_recurrency === 'semanal'"
              xs12
              md6
              class="px-1"
            >
              <v-select
                label="Dia da semana"
                :items="[
                  'segunda-feira',
                  'terça-feira',
                  'quarta-feira',
                  'quinta-feira',
                  'sexta-feira',
                  'sábado',
                  'domingo',
                ]"
                v-model="get_funcionario.receive_payment_week_date"
              ></v-select>
            </v-flex>
            <v-flex
              v-if="get_funcionario.receive_payment_recurrency === 'anual'"
              xs12
              md6
              class="px-1 pl-6"
            >
              <v-date-picker
                :color="$theme.secondary"
                label="Dia do ano"
                v-model="get_funcionario.receive_payment_year_date"
              ></v-date-picker>
            </v-flex>
            <v-flex
              v-if="get_funcionario.receive_payment_recurrency === 'manual'"
              xs12
              md6
              class="px-1 pl-6"
            >
              <div class="expande-horizontal fonte centraliza">
                <span class="text-center">
                  Não será adicionado saldo de forma automática, para fazer
                  adicionar manualmente
                  <v-chip
                    :color="$theme.secondary"
                    dark
                    x-small
                    @click="openValeSalarioEmitter('salario')"
                    >clique aqui</v-chip
                  >.
                </span>
              </div>
            </v-flex>
          </div>
        </v-flex>
      </div>
      <div
        style="min-height: 340px;"
        v-show="tab === 2"
        class="expande-horizontal fonte wrap"
      >
        <v-flex class="pa-3" xs12>
          <div class="expande-horizontal column centraliza">
            <h2
              class="fonte"
              :class="balance < 0 ? 'red--text' : 'green--text'"
            >
              {{ $helper.formataSaldo(balance) }}
            </h2>
            <span class="mb-3 fonte fonteMini">Resumo do saldo</span>
            <div>
              <v-btn
                class="mr-2"
                x-small
                rounded
                @click="openValeSalarioEmitter('vale')"
                dark
                :color="$theme.secondary"
                >+ vale</v-btn
              >
              <v-btn
                x-small
                rounded
                @click="openValeSalarioEmitter('salario')"
                dark
                :color="$theme.secondary"
                >+ saldo</v-btn
              >
            </div>
          </div>
        </v-flex>
        <v-flex v-if="transactions.length" class="pa-3 pr-6 pt-0" xs12>
          <v-list
            three-line
            :style="`min-height: 280px;max-height: ${$vuetify.breakpoint.smAndDown ? '60vh;' : '280px'}; overflow: auto;`"
            dense
            color="transparent"
          >
            <v-list-item
              @click="abre_modal_view_transacao(transaction)"
              class="item-transac"
              v-for="transaction in transactions"
              :key="transaction._id"
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ $helper.formataSaldo(transaction.valor) }}
                </v-list-item-title>
                <v-list-item-title>
                  {{ transaction.descricao }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Emitido {{ $moment(transaction.created_at).format("LLLL") }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-chip x-small dark :color="$theme.primary">
                    <v-icon size="13">mdi-flag</v-icon>
                    {{ transaction.tipo_de_transacao }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
        <v-flex class="pa-3" v-else xs12>
          <div
            class="expande-horizontal fonte centraliza"
            style="border: 1px solid #f2f2f2; min-height: 200px;"
          >
            <span>Sem movimentação!</span>
          </div>
        </v-flex>
      </div>
      <v-spacer></v-spacer>
    </v-card>
    <v-dialog v-model="emit_vale_salario_dialog" width="600">
      <v-card class="expande-horizontal wrap">
        <v-flex xs12>
          <v-list class="fonte">
            <v-list-item>
              <v-list-item-avatar
                :color="$theme.secondary"
                @click="closeValeSalarioEmitter"
              >
                <v-icon color="#fff">
                  mdi-arrow-left
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte font-weight-bold">
                  Informações do
                  {{
                    emit_vale_salario_form.type === "vale"
                      ? "vale"
                      : "saldo de salário"
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ get_funcionario.nome }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-flex>
        <v-flex xs12>
          <v-form ref="formValeSalario">
            <v-flex xs12 class="pa-6 pb-0">
              <div class="expande-horizontal">
                <h2 class="fonte green--text">
                  {{
                    $helper.formataSaldo(
                      emit_vale_salario_form.value.replace(",", ".") || 0
                    )
                  }}
                </h2>
              </div>
            </v-flex>
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="Informe o valor"
                v-mask="['#,##', '##,##', '###,##', '####,##', '#####,##']"
                v-model="emit_vale_salario_form.value"
                dense
                autofocus
                @keyup.enter.prevent="emitSalarioVale"
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
          </v-form>
          <v-flex class="pa-6 pt" xs12>
            <v-btn
              class="fonte font-weight-bold"
              small
              :color="$theme.secondary"
              dark
              @click="emitSalarioVale"
            >
              Adicionar
              <v-icon class="ml-2">mdi-cash-fast</v-icon>
            </v-btn>
          </v-flex>
        </v-flex>
      </v-card>
    </v-dialog>
    <ModalViewTransaction />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalViewTransaction from "@/apps/Transacoes/components/modalView.vue";
import extenso from "extenso";

export default {
  components: { ModalViewTransaction },
  data() {
    return {
      tab: 0,
      transactions: [],
      emit_vale_salario_form: {
        value: ""
      },
      emit_vale_salario_dialog: false
    };
  },
  computed: {
    ...mapGetters([
      "get_funcionario",
      "get_permissoes",
      "get_permissoes_filtros",
      "get_modal_view_funcionario",
      "getLoggedUser"
    ]),
    balance() {
      let balance = 0;
      const calcBalance = transaction => {
        switch (transaction.tipo_de_transacao) {
          case "salario":
            balance += transaction.valor;
            break;
          case "vale":
            balance -= transaction.valor;
            break;
        }
      };
      this.transactions.map(transaction => calcBalance(transaction));
      return balance;
    }
  },
  methods: {
    ...mapActions([
      "criar_funcionario",
      "listar_permissoes",
      "atualizar_funcionario",
      "listarProfessores",
      "fecha_modal_view_funcionario",
      "abre_modal_view_transacao"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_funcionario._id
          ? this.atualizar_funcionario()
          : this.criar_funcionario();
      }
    },
    openValeSalarioEmitter(type) {
      this.emit_vale_salario_dialog = true;
      this.emit_vale_salario_form = {
        funcionario: this.getLoggedUser,
        cliente: this.get_funcionario,
        type: type,
        value: ""
      };
    },
    closeValeSalarioEmitter() {
      this.emit_vale_salario_dialog = false;
    },
    formatNumberToExtense() {
      const value = this.emit_vale_salario_form.value || "0";
      const value_replaced = parseInt(value.replace(",", "."));
      this.emit_vale_salario_form.value_extense = extenso(value_replaced, {
        mode: "currency",
        currency: { type: "BRL" }
      });
      this.$forceUpdate();
    },
    emitVale() {
      if (this.$refs.formValeSalario.validate()) {
        this.$store.dispatch("enableLoading");
        this.$run("transacoes/criar-vale", this.emit_vale_salario_form).then(
          res => {
            this.emit_vale_salario_form._id = res._id ? res._id : res.data._id;
            this.$store.dispatch("disableLoading");
            this.closeValeSalarioEmitter();
            this.getValeSalario();
            this.$refs.emitterValeSalario.generatePdf();
          }
        );
      }
    },
    emitSalario() {
      if (this.$refs.formValeSalario.validate()) {
        this.$store.dispatch("enableLoading");
        this.$run("transacoes/criar-salario", this.emit_vale_salario_form).then(
          res => {
            this.emit_vale_salario_form._id = res._id ? res._id : res.data._id;
            this.$store.dispatch("disableLoading");
            this.closeValeSalarioEmitter();
            this.getValeSalario();
            this.$refs.emitterValeSalario.generatePdf();
          }
        );
      }
    },
    getValeSalario() {
      this.$run("transacoes/get-vale-salario", {
        _id: this.get_funcionario._id
      }).then(res => {
        this.transactions = res.data;
      });
    },
    emitSalarioVale() {
      if (this.emit_vale_salario_form.type === "vale") {
        this.emitVale();
      }
      if (this.emit_vale_salario_form.type === "salario") {
        this.emitSalario();
      }
    }
  },
  created() {
    this.listar_permissoes_filtros.all = true;
    this.listar_permissoes();
    this.getValeSalario();
  }
};
</script>

<style>
.item-transac {
  background: #f0f0f2;
  border-radius: 6px;
  margin-bottom: 6px;
}
</style>
