<template>
  <v-flex class="pa-3" :style="`background: ${$theme.background};`" xs12>
    <v-simple-table v-if="false" style="border: 1px solid #E5E5E5" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <!-- <td class="font-weight-bold"> </td> -->
            <td class="font-weight-bold">#ID</td>
            <td class="font-weight-bold">NOME</td>
            <td class="font-weight-bold">CPF</td>
            <td class="font-weight-bold">EMAIL</td>
            <td class="font-weight-bold">TELEFONE</td>
            <td class="font-weight-bold"></td>
          </tr>
        </thead>
        <tbody>
          <tr
            :style="
              `cursor: pointer; background: ${i % 2 === 0 ? '#f2f2f2' : '#fff'}`
            "
            v-for="(item, i) in get_funcionarios.docs"
            :key="item.id"
          >
            <!-- <td style="width: 10px"> <v-checkbox :color="$theme.primary" @click="setMarcado($event, item)" dense hide-details></v-checkbox> </td> -->
            <td
              style="width: 10px"
              @click="abre_modal_view_funcionario(item)"
              class="font-weight-bold"
            >
              {{ item.id_funcionario }}
            </td>
            <td @click="abre_modal_view_funcionario(item)">{{ item.nome }}</td>
            <td @click="abre_modal_view_funcionario(item)">{{ item.cpf }}</td>
            <td @click="abre_modal_view_funcionario(item)">{{ item.email }}</td>
            <td @click="abre_modal_view_funcionario(item)">{{ item.phone }}</td>
            <td style="width: 10px">
              <v-btn
                @click="
                  createConfirmAction({
                    message: 'Deseja remover este funcionario?',
                    icon: 'mdi-warning',
                    action: 'excluir_funcionario',
                    action_value: item
                  })
                "
                icon
                ><v-icon> mdi-delete </v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-list class="pa-0 ma-0 fonte" color="transparent" dense>
      <v-list-item
        @click="abre_modal_view_funcionario(user)"
        class="list-user-style animate__animated animate__fadeInUp"
        v-for="user in get_funcionarios.docs"
        :key="user._id"
      >
        <v-avatar class="mr-3">
          <v-img
            v-if="user.photo.length > 0 ? true : false"
            :src="user.photo[0]"
          ></v-img>
          <v-icon v-else>
            mdi-account-circle-outline
          </v-icon>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">
            {{ user.nome }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_funcionarios"])
  },
  methods: {
    ...mapActions(["abre_modal_view_funcionario", "createConfirmAction"]),
  }
};
</script>

<style>
.list-user-style {
  background: #f0f0f2;
  border-radius: 6px;
  margin-bottom: 6px;
}
</style>
